import React from 'react';

interface ServiceItemProps {
  title: string;
  description: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ title, description }) => {
  return (
    <div className="card h-100">
      <div className="card-body d-flex flex-column">
        <h5 className="card-title">{title}</h5>
        <p className="card-text flex-grow-1">{description}</p>
      </div>
    </div>
  );
};

const ServicesList: React.FC = () => {
  const services = [
    {
      title: 'House Wiring',
      description: 'Offering domestic, commercial, and industrial house wiring services.',
    },
    {
      title: 'CCTV Camera Installation',
      description: 'Professional CCTV camera installation services for enhanced security.',
    },
    {
      title: 'AC Installation',
      description: 'Expert AC installation services to keep your space cool and comfortable.',
    },
    {
      title: 'Electric Fence Installation',
      description: 'Secure your property with our reliable electric fence installation services.',
    },
    {
      title: 'Gate Automation',
      description: 'Automate your gates for convenience and enhanced security with our gate automation services.',
    },
    {
      title: '',
      description: 'And many more',
    },
  ];

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Our Services</h2>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {services.map((service, index) => (
          <div className="col" key={index}>
            <ServiceItem title={service.title} description={service.description} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesList;
