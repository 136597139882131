import React from 'react';
import './Footer.css';
import facbook from './footer_assets/social/facebook.png';
import tiktok from './footer_assets/social/icons8_tiktok_50px_1.png';
import linkedin from './footer_assets/social/LinkedIn.png';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <p>&copy; Highest Point Electrical Engineering.</p>
        </div>

        <div className="footer-right">
          <div className="social-media">
            <p>Follow us on</p>
            <a href="https://web.facebook.com/highestpointelectricalengineeringltd" target="_blank" rel="noopener noreferrer">
              <img src={facbook} alt="Facebook" />
            </a>
            <a href="https://web.facebook.com/highestpointelectricalengineeringltd" target="_blank" rel="noopener noreferrer">
              <img src={facbook} alt="Facebook" />
            </a>
            <a href="https://www.tiktok.com/@abrahamtettehpascal?_t=8m5wYtHGF2U&_r=1" target="_blank" rel="noopener noreferrer">
              <img src={tiktok} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/in/abraham-tetteh-37114329b/" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
