import React from 'react';
import './Gallery.css';

interface GalleryProps {
  images: string[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  return (
    <div className="gallery-container">
      <h1 className="gallery-title">Gallery</h1>
      <div className="image-grid">
        {images.map((imageUrl, index) => (
          <img key={index} className="gallery-image" src={imageUrl} alt={`Image ${index}`} />
        ))}
      </div>
    </div>
  );
};

export default Gallery;