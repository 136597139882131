import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Navbar.module.css';  
import HomePage from '../../../../pages/home/Home';
import AboutPage from '../../../../pages/about/About';
import ServicePage from '../../../../pages/services/Service'; 
import ContactsPage from '../../../../pages/contacts/Contact';  
import Footer from '../../Footer/Footer';
import Logo from './logo/Logo.jpg';
import {Helmet} from "react-helmet";


const NavMenu: React.FC = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('');
  const [isActive, setIsActive] = useState(false);

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  // Cleanup function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };

  useEffect(() => {
    // Set the current page based on the URL path
    const normalizedPathname = location.pathname.replace('/', '');
    setCurrentPage(normalizedPathname);
    // Update page title
    updatePageTitle(normalizedPathname);
  }, [location.pathname]);

  const updatePageTitle = (normalizedPathname: string) => {
    const pageTitle = getPageTitle(normalizedPathname);
    document.title = pageTitle;
  };

  const getPageTitle = (pathname: string) => {
    const companyName = 'Highest Point Electricals Engineering';

    switch (pathname) {
      case '':
        return `Home | ${companyName}`;
      case 'about':
        return `About Us | ${companyName}`;
      case 'services':
        return `Services | ${companyName}`;
      case 'contact':
        return `Contact Us | ${companyName}`;
      default:
        return companyName;
    }
  };

  const handleNavigation = (page: string) => {
    setCurrentPage(page);
    updatePageTitle(page);
  };

  return (
    <div className={`${styles.NavApp}`}>
      <Helmet>
        <title>{getPageTitle(currentPage)}</title>
        <meta name="description" content="Your meta description here" />
        {/* Add more meta tags as needed */}
      </Helmet>

      <header className='App-hearder'>
        <nav className={`${styles.navbar}`}>
          <a href="/" className={`${styles.logo}`}>
            <img src={Logo} alt="Logo" />
          </a>
          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={removeActive}><span  className={`${styles.navLink}`} onClick={() => handleNavigation('')}>HOME</span></li>
            <li onClick={removeActive}><span  className={`${styles.navLink}`} onClick={() => handleNavigation('about')}>ABOUT US</span></li>
            <li onClick={removeActive}><span  className={`${styles.navLink}`} onClick={() => handleNavigation('services')}>SERVICES</span></li>
            <li onClick={removeActive}><span   className={`${styles.navLink}`} onClick={() => handleNavigation('contact')}>GET IN TOUCH</span></li>
          </ul>
          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav> 
      </header>
      <div className={`${styles.maincontent}`}>
        <div className={`${styles.content}`}>
          {currentPage === '' && <HomePage />}
          {currentPage === 'about' && <AboutPage />}
          {currentPage === 'services' && <ServicePage />}
          {currentPage === 'contact' && <ContactsPage />}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default NavMenu;
