import React from 'react';
import './Home.css';
import targetIcon from "./background/bullseye/target_FILL0_wght400_GRAD0_opsz24.png"
import ControlledCarousel from "./Carousle.image"

const Home: React.FC = () => {
 
  return (
    <div>
      
       <ControlledCarousel/>       
      
      <div className="row row1">
          <div className="col-md-6">
            <h2 style={{ textAlign: 'center' }}>Mission </h2>
            <p className="vision-point">
            <img src={targetIcon} alt="icon" />
            <span>
              To empower our clients in achieving their goals through the provision of innovative electrical solutions.
              We are dedicated to delivering electrical systems that are reliable, efficient, and sustainable, surpassing industry standards.
              Safety and customer satisfaction remain our utmost priority in all endeavors.
            </span>
              </p> 
             </div>
             <div className="col-md-6">
            <h2 style={{ textAlign: 'center' }}>Vision</h2>
            <div className="col">
              <div className="vision-point">
                <img src={targetIcon} alt="icon" />
                <span>To be the leading provider of innovative electrical solutions, driving advancements in technology and sustainability, and setting new industry standards.</span>
              </div>
              <div className="vision-point">
                <img src={targetIcon} alt="icon" />
                <span>To create a smarter and more connected world through our cutting-edge electrical systems, empowering individuals, businesses, and communities to thrive.</span>
              </div>
              <div className="vision-point">
                <img src={targetIcon} alt="icon" />
                <span>To be recognized as the go-to partner for reliable and efficient electrical infrastructure, delivering exceptional value and exceeding customer expectations.</span>
              </div>
            </div>
          </div>
        </div>

    </div>
  );
};

export default Home;