import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import HomewiringImage from "./background/images/carousel/img4.jpg";
import CCTVIntallationImage from "./background/images/carousel/CCTV.jpg";
import ACInstallation from "./background/images/carousel/ac.jpg";
import GateAutomation from "./background/images/carousel/Gate_automation.jpg";
import "./Home.css";

function ControlledCarousel() {
  const [index, setIndex] = useState<number>(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel  activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img src={HomewiringImage} alt="Slide 1" className="home-container"/>
        <Carousel.Caption>
          <h3>House Wiring</h3>
          <p>Offering domestic, commercial, and industrial house wiring services.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="home-container" src={CCTVIntallationImage} alt="Slide 2" />
        <Carousel.Caption>
          <h3>CCTV Camera Installation</h3>
          <p>Professional CCTV camera installation services for enhanced security.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ACInstallation} alt="Slide 3" className="home-container"/>
        <Carousel.Caption>
          <h3>AC Installation</h3>
          <p>Expert AC installation services to keep your space cool and comfortable.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={GateAutomation} alt="Slide 4" className="home-container"/>
        <Carousel.Caption>
          <h3>Gate Automation</h3>
          <p>Automate your gates for convenience and enhanced security with our gate automation services.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  ); 
}

export default ControlledCarousel;
