import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavMenu from "./components/layout/Header/navMenu/Navbar"
import 'bootstrap/dist/css/bootstrap.css';
const App: React.FC = () => {
  return (
   
      <Router>
      <NavMenu />
      </Router>
    
  );
};

export default App;
