import React, { useState, useRef, FormEvent } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import CustomButton from '../../components/common/Button/Button';

const ContactUs: React.FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    if (form.current) {
      emailjs
        .sendForm('service_6v0jz9b', 'template_mb7bo4j', form.current, {
          publicKey: 'kQtTqWGC_P07OfKY_',
        })
        .then(
          () => {
            setSuccessMessage('Your message has been submitted successfully.');
            if (form.current) {
              form.current.reset(); // Clear form fields
            }
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="contact-container contactbody">
      <h2>Contact Us</h2>
      <p>Get in touch with us for any inquiries or assistance.</p>

      <div className="row">
        <div className="col-md-6">
          <div className="contact-info">
            <h3>Contact Information</h3>
            <p>Email: highestpointelectricals055@gmail.com</p>
            <p>Phone: +233551369576 / +233244153543/ +233208129178 </p>
            <p>Location: Ashaiman, Accra Opera Square, Ghana</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="contact-options">
            <h3>Reach out to us</h3>
            <p>Fill out the form below or use one of the following methods:</p>

            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  name="from_name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required
                  name="email"
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  className="form-control"
                  id="message"
                  required
                  name="message"
                ></textarea>
              </div>
              <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: 10 }}>
                <CustomButton text="Submit"/>
              </div>
              {successMessage && <p className="text-success">{successMessage}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
