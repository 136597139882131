import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import teamImage from './team/team.jpg';
import PMImage from './team/PM.jpg';
import SEImage from './team/SE.jpg'; 
import ChairmanImage from './team/BoardChai.jpg';
import DirectorImage from './team/Com_Dir.jpg'; 
import "./about.css"

const About: React.FC = () => {
  return (
    <div className="py-5">
      <div className='about-container'>
        <Row>
          <Col md={6} className='row-items'>
            <div>
              <p>
                Welcome to Highest Point Electrical Engineering Ltd, your premier provider of electrical services.
                With over five years of experience, we have been serving Ghana and surrounding areas with top-notch electrical solutions for residential, commercial, and industrial clients.
              </p>
              <p>
                At Highest Point Electrical Engineering Ltd, we are committed to delivering the highest quality workmanship and exceptional customer service.
                Our team of skilled electricians, consisting of 25 professional staff with over 10 years of experience in the construction field, is dedicated to meeting and exceeding the expectations of our clients on every project.
              </p>
              <p>
                Whether you need electrical installation, repair, maintenance, or troubleshooting services, you can trust Highest Point Electrical Engineering Ltd to get the job done right the first time.
                We pride ourselves on our professionalism, reliability, and integrity in every aspect of our work.
              </p>
            </div>
          </Col>
          <Col md={6} className='row-items'>
            <div className="text-center">
              <Image src={teamImage} alt="Team" className="img-fluid team-image" />
            </div>
          </Col>
        </Row>
        <Row className="company-board mt-5">
          <h2 style={{ textAlign: 'center', marginBottom:"40px" }}>Company Board</h2>
          <Col md={3} sm={6} className="text-center">
            <Image src={ChairmanImage} alt="Thomas Awuah Abbabio" className="img-fluid board-member image"  />
            <p className="board-member-name">Mr Thomas Awuah Abbabio</p>
            <p className="board-member-position">Board Chairman and Chartered Accountant with over 30 years of experience.</p>
          </Col>
          <Col md={3} sm={6} className="text-center">
            <Image src={DirectorImage} alt="Emmanuel Osei Boahen" className="img-fluid board-member image"  />
            <p className="board-member-name">Mr Emmanuel Osei Boahen</p>
            <p className="board-member-position">Company Director</p>
          </Col>
          <Col md={3} sm={6} className="text-center">
            <Image src={PMImage} alt="Future Agbanator" className="img-fluid board-member image" />
            <p className="board-member-name">Mr Future Agbanator</p>
            <p className="board-member-position">Executive Staff of Controller and Accountant General GH.</p>
          </Col>
          <Col md={3} sm={6} className="text-center">
            <Image src={SEImage} alt="Abraham Tetteh" className="img-fluid board-member image"/>
            <p className="board-member-name">Mr Abraham Tetteh</p>
            <p className="board-member-position">CEO, Electrical Contractor.</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
