import React from 'react';
import ImageComponent from '../gallery/assets';
import './Services.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ServiceList from './Service.list';

const ServicePage: React.FC = () => {
  return (
    <div className="service-body">
      <div className="container">
      <ServiceList/>
      </div>
      <ImageComponent />
    </div>
  );
};

export default ServicePage;